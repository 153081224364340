import * as amplitudeBrowser from "@amplitude/analytics-browser";

const KEY = (process.env.AMPLITUDE_KEY || process.env.NEXT_PUBLIC_AMPLITUDE_KEY) as string;

const routesToEventsMap: Record<string, string> = {
  "/": "Page Viewed - Home",
  "/contact": "Contact Requested",
  "/services": "Page Viewed - Services",
  "/projects": "Page Viewed - Projects",
  "/team": "Page Viewed - Team",
  "/careers": "Page Viewed - Careers",
  "/careers#roles": "Open Roles Viewed",
  "/careers/culture": "Page Viewed - Culture",
  "/careers/perks-and-benefits": "Page Viewed - Benefits",
  "/careers/values": "Page Viewed - Values",
  "/blog": "Page Viewed - Blog",
  "/press": "Page Viewed - Press",
  "/investments": "Page Viewed - Investments",
  "/iphone-app-design": "Page Viewed - Design",
  "/product-led-growth": "Page Viewed - Growth",
  "/android-app-development": "Page Viewed - Android",
  "/ios-app-development": "Page Viewed - iOS",
  "/digital-transformation-services-nyc": "Page Viewed - Digital Transformation",
  "/web-development-agency": "Page Viewed - Web Development",
  "/smart-contract-developer": "Page Viewed - Smart Contract",
  "/web3-developers": "Page Viewed - Web3",
  "/defi-development-company": "Page Viewed - DeFi",
};

type InstanceMode = "server" | "browser";
export class AnalyticsService {
  tracking = false;
  amplitude: typeof amplitudeBrowser;
  deviceId = "unknown-device-id";
  mode: InstanceMode | undefined = undefined;
  defaultEventProperties = {};

  constructor(mode: InstanceMode) {
    this.amplitude = amplitudeBrowser;

    if (!this.tracking && typeof window !== "undefined") {
      this.amplitude.init(KEY, {
        instanceName: mode,
        defaultTracking: {
          pageViews: false,
          formInteractions: false,
          sessions: false,
          fileDownloads: false,
        },
      });
      this.tracking = true;
      console.log("Now tracking events in amplitude from the:", mode);
    }

    this.defaultEventProperties = {
      mode,
    };
  }

  trackPageView(
    route: string,
    eventProperties?: Record<string, any>,
    eventOptions?: amplitudeBrowser.Types.EventOptions
  ) {
    const targetPage = routesToEventsMap[route];
    if (targetPage) {
      return this.amplitude.track(
        targetPage,
        {
          ...this.defaultEventProperties,
          ...eventProperties,
        },
        {
          device_id: this.deviceId,
          ...eventOptions,
        }
      );
    }

    // ignore specific dynamic routes that may have already been tracked on the page itself
    if (/^\/careers\/\d+$/.test(route)) return; // individual job page
    if (/^\/team\/[a-zA-Z-]+$/.test(route)) return; // individual job page

    return this.amplitude.track(
      "Page Viewed - Other",
      {
        ...this.defaultEventProperties,
        ...eventProperties,
        page: route,
      },
      {
        device_id: this.deviceId,
        ...eventOptions,
      }
    );
  }

  trackContactSubmit() {
    this.amplitude.track("Contact Form Submitted", { ...this.defaultEventProperties });
  }
  trackOpenRolesView() {
    this.amplitude.track("Open Roles Viewed", { ...this.defaultEventProperties });
  }
  trackOpenRolesSelect(eventProperties: { role_department?: string; role_name?: string }) {
    this.amplitude.track("Open Roles Selected", {
      ...this.defaultEventProperties,
      ...eventProperties,
    });
  }
}

const Analytics = new AnalyticsService("browser");

export default Analytics;
