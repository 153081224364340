import { useEffect } from "react";

import gsap from "gsap";
import { CustomEase } from "gsap/dist/CustomEase";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";

import Curtain from "components/Curtain";

import AnalyticsService from "services/analytics";

import "styles/globals.scss";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    AnalyticsService.trackPageView(router.asPath);
  }, [router.asPath]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <Curtain />
      <Component {...pageProps} />
    </>
  );
};

export default MyApp;
