import React, { useEffect } from "react";

import { gsap } from "gsap";
import { useRouter } from "next/router";

const Curtain = () => {
  const router = useRouter();

  useEffect(() => {
    // function on animation end
    // Runs when the next page has finished loading
    const animEnd = () => {
      gsap.to(".cover-strip", {
        top: 0,
        duration: 0.75,
        ease: "Expo.easeInOut",
        stagger: -0.1,
      });
    };

    // Curtain listeners
    router.events.on("routeChangeComplete", animEnd);
    router.events.on("routeChangeError", animEnd);

    // Cleanup and clear
    return () => {
      router.events.off("routeChangeComplete", animEnd);
      router.events.off("routeChangeError", animEnd);
    };
  }, [router]);

  return (
    <div className="relative z-50 flex flex-col overflow-hidden" aria-hidden>
      <div className="cover-strip cover fixed left-0 top-0 h-screen w-3/12 bg-brand-300"></div>
      <div className="cover-strip cover fixed left-1/4 top-0 h-screen w-3/12 bg-brand-500"></div>
      <div className="cover-strip cover fixed left-2/4 top-0 h-screen w-3/12 bg-brand-700"></div>
      <div className="cover-strip cover fixed left-3/4 top-0 h-screen w-3/12 bg-brand-900"></div>
    </div>
  );
};

export default Curtain;
